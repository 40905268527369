import React from 'react'
import { IconComponent } from './iconComponentType'

export const ClockIcon: IconComponent = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="45.12" height="45.115" viewBox="0 0 45.12 45.115">
      <defs>
        <linearGradient id="a" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#ffb81c"/><stop offset="1" stopColor="#f35a21"/>
        </linearGradient>
      </defs>
      <g transform="translate(-1509.679 -1385.115)">
        <circle fill="url(#a)" cx="3" cy="3" r="3" transform="translate(1547 1392)"/>
        <g transform="translate(1509.74 1385.107)">
          <g transform="translate(34.084 3.955)">
            <path fill="#1c1f2a" d="M388.928,44.992l-.023-.016a.881.881,0,1,0-1.01,1.443l.021.015a.881.881,0,1,0,1.013-1.442Z" transform="translate(-387.519 -44.816)"/>
          </g>
          <path fill="#1c1f2a" d="M27.746,44.5a22.283,22.283,0,0,0,7.49-3.325l.068-.047.253-.177a22.29,22.29,0,0,0,6.011-6.341l0-.006q.1-.158.2-.318t.177-.3l.02-.034a22.284,22.284,0,0,0,2.688-7.174c.015-.077.029-.153.043-.23.009-.051.018-.1.027-.154a22.373,22.373,0,0,0-1.815-13.42A3.868,3.868,0,1,0,41.484,14.1,20.676,20.676,0,0,1,43.3,22.443a.879.879,0,0,0-.011.131,21.044,21.044,0,0,1-.3,3.532c-.007.042-.014.083-.022.125-.013.076-.028.152-.043.229a21.1,21.1,0,0,1-2.473,6.6l-.025.043c-.051.087-.1.174-.156.26a21.051,21.051,0,0,1-5.739,6.157l-.211.148-.085.058a21.089,21.089,0,0,1-6.893,3.059l-.176.042L27,42.865a21.089,21.089,0,0,1-7.542.267l-.1-.015-.254-.041a21.09,21.09,0,0,1-7.553-2.835l-.008,0c-.1-.06-.192-.121-.287-.182s-.181-.116-.271-.176l-.023-.016A21.107,21.107,0,0,1,5.833,35c-.045-.06-.09-.12-.134-.181l-.08-.111a21.066,21.066,0,0,1-3.448-7.758c-.018-.083-.036-.167-.053-.25L2.1,26.6a21.1,21.1,0,0,1-.178-7.032c.011-.075.022-.151.034-.226.007-.044.014-.089.021-.133a21.046,21.046,0,0,1,.872-3.437,20.793,20.793,0,0,1,3.06-5.746l.006-.008q.111-.147.224-.291A21.14,21.14,0,0,1,7.8,7.864,20.781,20.781,0,0,1,21.075,1.818l.037,0,.34-.02.121-.005.261-.01.212-.005.175,0h.311a20.74,20.74,0,0,1,9.154,2.14.881.881,0,1,0,.779-1.58A22.5,22.5,0,0,0,22.613.008H22.56q-.184,0-.367,0l-.162,0-.26.006-.255.01-.16.007-.341.02-.069,0a22.541,22.541,0,0,0-16.19,8.58A22.312,22.312,0,0,0,.24,18.922c-.009.054-.017.108-.026.162-.012.076-.023.152-.034.228a22.281,22.281,0,0,0,.193,7.643l.016.082q.029.144.061.289a22.294,22.294,0,0,0,3.736,8.4l.093.129.139.189a22.282,22.282,0,0,0,5.563,5.282l.024.016q.146.1.295.192t.309.2l.011.007A22.289,22.289,0,0,0,18.8,44.812l.307.049.08.012a22.281,22.281,0,0,0,8.19-.287l.186-.042Zm9.89-33.922a2.24,2.24,0,1,1,2.24,2.24A2.242,2.242,0,0,1,37.636,10.579Z"/>
        </g>
      </g>
      <g transform="translate(13 18)">
        <g transform="translate(0 0)">
          <path fill="#1c1f2a" d="M115.272,192.687a2.351,2.351,0,0,0-2.229,1.614h-6.059a.734.734,0,1,0,0,1.467h6.059a2.357,2.357,0,0,0,1.5,1.5v8.008a.733.733,0,1,0,1.467,0v-8.008a2.347,2.347,0,0,0-.733-4.576Zm0,3.227a.88.88,0,1,1,.88-.88A.881.881,0,0,1,115.272,195.914Z" transform="translate(-106.25 -192.687)"/>
        </g>
      </g>
    </svg>
  )
}
