import React from 'react'
import { IconComponent } from './iconComponentType'

export const BrokenBarIcon:IconComponent = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" { ...props } viewBox="0 0 29 20" >
      <g transform="translate(-335.535 -10)" fill="#1c1f2a" opacity="0.997">
        <rect width="29" height="2" transform="translate(335.535 10)"/>
        <rect width="21" height="2" transform="translate(343.535 19)"/>
        <rect width="13" height="2" transform="translate(351.535 28)"/>
      </g>
    </svg>
  )
}
