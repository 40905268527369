import React from 'react'
import { IconComponent } from './iconComponentType'

export const ChatToUsIcon:IconComponent = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" { ...props } viewBox="0 0 36.688 37.362" >
      <g transform="translate(-98.1 -167.76)">
        <g transform="translate(98.1 167.76)">
          <path fill="#f35a21" d="M116.53,167.76c-.011.011-.031.011-.06.011a18.328,18.328,0,0,0-14.375,29.75l-2.673,6.206a1,1,0,0,0,.52,1.311,1.014,1.014,0,0,0,.571.07l9.8-1.722a17.662,17.662,0,0,0,6.106,1.061,18.344,18.344,0,0,0,.11-36.688Zm-.1,34.7a16.054,16.054,0,0,1-5.655-1.031.965.965,0,0,0-.531-.05l-8.248,1.442,2.182-5.076a1.008,1.008,0,0,0-.16-1.051,15.983,15.983,0,0,1-2.783-4.625,16.344,16.344,0,1,1,31.563-6.046v.06A16.388,16.388,0,0,1,116.429,202.456Z" transform="translate(-98.1 -167.76)"/>
        </g>
        <g transform="translate(109.242 182.445)">
          <path fill="#f35a21" d="M118.018,185.568h-5.405a1,1,0,1,0,0,2h5.405a1,1,0,1,0,0-2Z" transform="translate(-111.611 -185.568)"/>
        </g>
        <g transform="translate(109.242 187.451)">
          <path fill="#f35a21" d="M125.025,191.638H112.612a1,1,0,1,0,0,2h12.413a1,1,0,1,0,0-2Z" transform="translate(-111.611 -191.638)"/>            
        </g>
      </g>
    </svg>
  )
}
