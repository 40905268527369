import React from 'react'
import { IconComponent } from './iconComponentType'

export const VisaDebitCardIcon: IconComponent = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="43.278" height="31.042" viewBox="0 0 43.278 31.042">
      <defs>
        <linearGradient id="a" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#ffb81c"/>
          <stop offset="1" stopColor="#f35a21"/>
        </linearGradient>
      </defs>
      <g transform="translate(-942 -1388.762)">
        <rect fill="url(#a)" width="43" height="6" transform="translate(942 1396)"/>
        <g transform="translate(1392.32 1029.561)">
          <path fill="#1c1f2a" d="M-407.909,360.067A2.94,2.94,0,0,0-410,359.2h-37.361a2.939,2.939,0,0,0-2.092.866,2.938,2.938,0,0,0-.866,2.092v25.126a2.938,2.938,0,0,0,.866,2.093,2.938,2.938,0,0,0,2.092.865H-410a2.939,2.939,0,0,0,2.092-.865,2.943,2.943,0,0,0,.866-2.093v-1.673a.846.846,0,0,0-.845-.845.846.846,0,0,0-.846.844h0v1.673A1.272,1.272,0,0,1-410,388.553h-37.361a1.273,1.273,0,0,1-1.268-1.268v-14.6h39.9V378a.847.847,0,0,0,.846.846.847.847,0,0,0,.845-.846V362.159A2.942,2.942,0,0,0-407.909,360.067Zm-39.452.824H-410a1.271,1.271,0,0,1,1.267,1.267v3.529h-39.9v-3.529A1.272,1.272,0,0,1-447.362,360.892Zm-1.268,10.1V367.38h39.9v3.614Z" transform="translate(0 0)"/>
          <circle fill="#1c1f2a" cx="0.845" cy="0.845" r="0.845" transform="translate(-408.733 380.963)"/>
        </g>
      </g>
    </svg>
  )
}
