import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { Icon } from '../icon/icon'

export const StyledTitleChevron = styled(Icon)<{$rotate?: boolean}>`
  color: ${props => props.theme.palette.primary.main};
  transform: ${props => props.$rotate ? 'rotate(180deg)' : 'none'};
  transform: translateX(${props => props.theme.typography.pxToRem(10)});
  margin-top: ${props => props.theme.typography.pxToRem(4)};
  font-size: ${props => props.theme.typography.pxToRem(14)};
  transition: transform 0.2s ease;
`

export const StyledTitleText = styled.a`
  color: ${props => props.theme.palette.text.secondary};
  font-size: ${props => props.theme.typography.pxToRem(22)};
  font-weight: 500;
  text-decoration: none;
  margin: ${props => props.theme.typography.pxToRem(32)} 0 0 ${props => props.theme.typography.pxToRem(30)};
  transition: color 0.2s ease;

  ${props => props.theme.breakpoints.up('sm')} {
    margin-left: 0;
  }

  &:hover {
    color: ${props => props.theme.palette.secondary.dark};

    ${StyledTitleChevron} {
      transform: translateX(${props => props.theme.typography.pxToRem(20)});
    }
  }
`

export const StyledCardIconContainer = styled(Grid)`
  margin-bottom: ${props => props.theme.typography.pxToRem(26)};
`

export const StyledCardList = styled(Grid)`
  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${props => props.theme.typography.pxToRem(20)};
  }
`
