import React from 'react'
import { IconComponent } from './iconComponentType'

export const FreeChargeIcon: IconComponent = (props) => {
  return (
    <svg { ...props } xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40.157" height="38.849" viewBox="0 0 40.157 38.849">
      <defs>
        <linearGradient id="a" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#ffb81c"/>
          <stop offset="1" stopColor="#f35a21"/>
        </linearGradient>
      </defs>
      <g transform="translate(450.32 -318.087)">
        <path fill="url(#a)" d="M-420.687,327.165a4.885,4.885,0,0,0-2.438-.66h-.019a4.859,4.859,0,0,0-3.448,1.422,4.868,4.868,0,0,0-1.446,3.459,4.865,4.865,0,0,0,1.424,3.466,4.862,4.862,0,0,0,3.457,1.446h.014a4.9,4.9,0,0,0,4.9-4.881A4.921,4.921,0,0,0-420.687,327.165Z" transform="translate(-1.364 -1.364)"/>
        <path fill="#1c1f2a" d="M-415.094,318.283l-11.77-.035h0a.727.727,0,0,0-.726.723.726.726,0,0,0,.722.727l11.771.035a3.47,3.47,0,0,1,2.468,1.033,3.476,3.476,0,0,1,1.017,2.475L-411.7,352a3.473,3.473,0,0,1-1.033,2.468,3.477,3.477,0,0,1-2.464,1.017h-.012l-30.177-.1a3.5,3.5,0,0,1-3.486-3.509l.087-28.76a3.473,3.473,0,0,1,1.034-2.468,3.471,3.471,0,0,1,2.463-1.017h.012l11.618.034h0a.715.715,0,0,0,.51-.21.716.716,0,0,0,.214-.513.718.718,0,0,0-.21-.512.722.722,0,0,0-.512-.214l-11.618-.034h-.018a4.906,4.906,0,0,0-3.482,1.439,4.908,4.908,0,0,0-1.462,3.491l-.087,28.76a4.965,4.965,0,0,0,2.467,4.295,4.932,4.932,0,0,0,2.463.667l30.178.1h.017a4.912,4.912,0,0,0,3.483-1.439,4.908,4.908,0,0,0,1.462-3.491l.087-28.761A4.953,4.953,0,0,0-415.094,318.283Z"/>
        <path fill="#1c1f2a" d="M-433.915,335.344a.9.9,0,0,0,.606-.9l.03-9.193a.725.725,0,0,0-.211-.513.716.716,0,0,0-.511-.213h0a.723.723,0,0,0-.511.209.722.722,0,0,0-.214.513l-.023,7.346-5.321-7.758a.722.722,0,0,0-.6-.315.7.7,0,0,0-.215.033.722.722,0,0,0-.512.69l-.03,9.5a.726.726,0,0,0,.361.629,1.155,1.155,0,0,0,.363.1.72.72,0,0,0,.511-.211.722.722,0,0,0,.214-.512l.023-7.171,5.076,7.4A.855.855,0,0,0-433.915,335.344Z"/>
        <path fill="#1c1f2a" d="M-424.488,324.555h-.017a5.443,5.443,0,0,0-3.864,1.594,5.443,5.443,0,0,0-1.618,3.871,5.447,5.447,0,0,0,1.593,3.881,5.523,5.523,0,0,0,1.139.881,5.491,5.491,0,0,0,2.732.737h.017a5.491,5.491,0,0,0,5.483-5.464A5.49,5.49,0,0,0-424.488,324.555Zm-.019,9.514h-.012a4,4,0,0,1-2.847-1.191,4.008,4.008,0,0,1-1.172-2.854,4.008,4.008,0,0,1,1.191-2.848,4,4,0,0,1,2.839-1.171h.016a4.019,4.019,0,0,1,2.008.544,4.051,4.051,0,0,1,2.011,3.5A4.038,4.038,0,0,1-424.507,334.069Z"/>
        <path fill="#1c1f2a" d="M-437.936,341.025h0a.723.723,0,0,0,.511-.21.726.726,0,0,0,.214-.513.727.727,0,0,0-.211-.513.722.722,0,0,0-.512-.214l-3.856-.012h0a.725.725,0,0,0-.724.722l-.031,9.514a.73.73,0,0,0,.361.63.511.511,0,0,0,.365.1.725.725,0,0,0,.724-.722l.013-4.122,2.825.009h0a.721.721,0,0,0,.51-.21.718.718,0,0,0,.214-.513.725.725,0,0,0-.722-.727l-2.826-.009.012-3.217Z"/>
        <path fill="#1c1f2a" d="M-433.215,340.316l-.031,9.513a.728.728,0,0,0,.361.63.725.725,0,0,0,.362.1l4.008.012h0a.725.725,0,0,0,.724-.722.725.725,0,0,0-.722-.727l-3.284-.011.01-3.307,2.989.01h0a.724.724,0,0,0,.51-.211.721.721,0,0,0,.214-.512.717.717,0,0,0-.211-.513.717.717,0,0,0-.511-.214l-2.989-.009.011-3.308,3.284.011h0a.725.725,0,0,0,.724-.723.726.726,0,0,0-.722-.727l-4.009-.013h0A.725.725,0,0,0-433.215,340.316Z"/>
        <path fill="#1c1f2a" d="M-418.714,341.087h0a.719.719,0,0,0,.511-.21.724.724,0,0,0,.214-.513.726.726,0,0,0-.723-.726l-4.009-.013h0a.724.724,0,0,0-.51.21.731.731,0,0,0-.215.513l-.031,9.513a.73.73,0,0,0,.361.629.707.707,0,0,0,.361.1l4.01.014h0a.728.728,0,0,0,.726-.723.727.727,0,0,0-.723-.728l-3.284-.01.011-3.308,2.989.01a.651.651,0,0,0,.513-.21.724.724,0,0,0,.214-.513.723.723,0,0,0-.211-.512.722.722,0,0,0-.512-.214l-2.988-.01.011-3.308Z"/>
        <path fill="#1c1f2a" d="M-430.68,319.67h0a.858.858,0,0,0,.427.115.9.9,0,0,0,.217-.028.844.844,0,0,0,.518-.395.839.839,0,0,0,.087-.644.845.845,0,0,0-.394-.518.863.863,0,0,0-.426-.114.853.853,0,0,0-.736.422.839.839,0,0,0-.087.644A.841.841,0,0,0-430.68,319.67Z"/>
      </g>
    </svg>
  )
}
