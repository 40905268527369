import React from 'react'
import { IconComponent } from './iconComponentType'

export const CalculatorIcon:IconComponent = (props) => {
  return (
    <svg {...props} viewBox="0 0 22.508 26.978">
      <defs>
        <linearGradient id="a" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stopColor="#ffb81c" /><stop offset="1" stopColor="#f35a21" /></linearGradient>
      </defs>
      <rect fill="url(#a)" width="15.421" height="5.552" transform="translate(3.334 4.154)" />
      <path fill="#1c1f2a" d="M123.089,169.324h-.883v-.883a.441.441,0,0,0-.883,0v.883h-.883a.441.441,0,0,0,0,.883h.883v.883a.441.441,0,0,0,.883,0v-.883h.883a.441.441,0,1,0,0-.883Z" transform="translate(-115.967 -155.695)" />
      <path fill="#1c1f2a" d="M283.089,192h-2.648a.441.441,0,0,0,0,.883h2.648a.441.441,0,0,0,0-.883Z" transform="translate(-264.814 -178.371)" />
      <path fill="#1c1f2a" d="M123.4,328.132a.427.427,0,0,0-.618,0l-1.015,1.015-1.015-1.015a.437.437,0,0,0-.618.618l1.015,1.015-1.015,1.015a.427.427,0,0,0,0,.618.427.427,0,0,0,.618,0l1.015-1.015,1.015,1.015a.427.427,0,0,0,.618,0,.427.427,0,0,0,0-.618l-1.015-1.015,1.015-1.015A.427.427,0,0,0,123.4,328.132Z" transform="translate(-115.967 -307.779)" />
      <g transform="translate(15.186 20.014)">
        <path fill="#1c1f2a" d="M283.089,352h-2.648a.441.441,0,0,0,0,.883h2.648a.441.441,0,0,0,0-.883Z" transform="translate(-280 -350.469)" /><ellipse fill="#1c1f2a" cx="0.538" cy="0.538" rx="0.538" ry="0.538" transform="translate(1.227 0)" /><ellipse fill="#1c1f2a" cx="0.538" cy="0.538" rx="0.538" ry="0.538" transform="translate(1.227 2.869)" />
      </g>
      <path fill="#1c1f2a" stroke="#1c1f2a" strokeWidth="0.5px" d="M271.862,192H250.844a.441.441,0,0,0,0,.883h21.018a.441.441,0,1,0,0-.883Z" transform="translate(-250.099 -174.631)" />
      <path fill="#1c1f2a" stroke="#1c1f2a" strokeWidth="0.5px" d="M16.443,0,.4.02a.381.381,0,0,0-.4.4.381.381,0,0,0,.4.4l16.04-.02a.381.381,0,0,0,.4-.4A.381.381,0,0,0,16.443,0Z" transform="translate(10.972 26.575) rotate(-90)" />
      <g transform="translate(0 0)">
        <path fill="#1c1f2a" d="M64.661,67.5H48.552a.552.552,0,0,0-.552.552v6.234a.552.552,0,0,0,.552.552H64.661a.552.552,0,0,0,.552-.552V68.052A.552.552,0,0,0,64.661,67.5Zm-.552,6.234H49.1V68.6H64.109Z" transform="translate(-45.352 -64.411)" />
        <g transform="translate(0 0)">
          <path fill="#1c1f2a" d="M19.75,11.5H13.737a.552.552,0,0,0,0,1.1H19.75a1.657,1.657,0,0,1,1.655,1.655V35.719a1.657,1.657,0,0,1-1.655,1.655H2.758A1.657,1.657,0,0,1,1.1,35.719V14.258A1.657,1.657,0,0,1,2.758,12.6H8.772a.552.552,0,0,0,0-1.1H2.758A2.762,2.762,0,0,0,0,14.258V35.719a2.762,2.762,0,0,0,2.758,2.758H19.75a2.762,2.762,0,0,0,2.758-2.758V14.258A2.762,2.762,0,0,0,19.75,11.5Z" transform="translate(0 -11.5)" />
          <path fill="#1c1f2a" d="M194.552,12.6a.552.552,0,1,0-.39-.162A.556.556,0,0,0,194.552,12.6Z" transform="translate(-183.297 -11.5)" />
        </g>
      </g>
    </svg>
  )
}
