import React from 'react'
import { IconComponent } from './iconComponentType'

export const WalletIcon:IconComponent = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props} viewBox="0 0 28.854 28.854">
      <defs>
        <linearGradient id="a" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stopColor="#ffb81c" /><stop offset="1" stopColor="#f35a21" /></linearGradient>
      </defs>
      <path fill="url(#a)" d="M571.233-2354.529l7.827-7.98,1.771-.917,2.983,2.534h1.522l1.751,1.177,2.49,5.188H571.233" transform="translate(-566.086 2363.947)" />
      <g transform="translate(0 0)">
        <g transform="translate(0 0)">
          <path fill="#1c1f2a" d="M27.164,15.564H26.6V11.619a3.274,3.274,0,0,0-2.549-3.188L21.486,3.988A2.816,2.816,0,0,0,18.239,2.7L16.374.834a2.818,2.818,0,0,0-3.981,0L4.877,8.35H3.269A3.272,3.272,0,0,0,0,11.619V25.6a3.272,3.272,0,0,0,3.269,3.269h7.5a.564.564,0,0,0,0-1.127h-7.5A2.144,2.144,0,0,1,1.127,25.6V11.619A2.144,2.144,0,0,1,3.269,9.477H23.331a2.144,2.144,0,0,1,2.142,2.142v3.945H21.3a3.043,3.043,0,0,0,0,6.087h4.17V25.6a2.144,2.144,0,0,1-2.142,2.142h-7.5a.564.564,0,0,0,0,1.127h7.5A3.272,3.272,0,0,0,26.6,25.6V21.65h.564a1.693,1.693,0,0,0,1.691-1.691V17.255A1.693,1.693,0,0,0,27.164,15.564Zm-7.679-11.8a1.676,1.676,0,0,1,1.025.786L22.7,8.35H10.555L18.2,3.934a1.677,1.677,0,0,1,1.281-.169ZM13.19,1.631a1.688,1.688,0,0,1,2.387,0l1.6,1.6L8.3,8.35H6.471ZM27.727,19.96a.564.564,0,0,1-.564.564H21.3a1.916,1.916,0,0,1,0-3.832h5.861a.564.564,0,0,1,.564.564Z" transform="translate(0 -0.011)" />
          <path fill="#1c1f2a" d="M366.894,319.988a.564.564,0,1,0,.4.165A.567.567,0,0,0,366.894,319.988Z" transform="translate(-345.685 -301.955)" />
          <path fill="#1c1f2a" d="M226.564,491.988a.564.564,0,1,0,.4.165A.568.568,0,0,0,226.564,491.988Z" transform="translate(-213.263 -464.262)" />
        </g>
      </g>
    </svg>
  )
}
