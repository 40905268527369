import styled from 'styled-components'
import { Container, Grid } from '@material-ui/core'
import { Icon } from '../icon/icon'
import { rem } from '../../utils'

export const StyledCardContainer = styled(Grid)`
  margin-right: ${props => props.theme.typography.pxToRem(84)};
`

export const StyledTitleText = styled.a`
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.theme.typography.pxToRem(20)};
  font-weight: 500;
  text-decoration: none;
  transition: margin-right 0.2s ease, color 0.2s ease;
`

export const StyledTitleCheveron = styled(Icon)<{$rotate?: boolean}>`
  position: absolute;
  top: ${props => props.theme.typography.pxToRem(6)};
  right: -${props => props.theme.typography.pxToRem(16)};
  color: ${props => props.theme.palette.primary.main};
  font-size: ${props => props.theme.typography.pxToRem(16)};
  transition: right 0.2s ease;
`

export const StyledTitleTextContainer = styled.div`
  position: relative;
  width: ${props => props.theme.typography.pxToRem(90)};
  overflow-wrap: break-word;
  cursor: pointer;

  &:hover {
    ${StyledTitleCheveron} {
      right: -${props => props.theme.typography.pxToRem(26)};
    }

    ${StyledTitleText} {
      color: ${props => props.theme.palette.secondary.dark};
    }
  }
`

export const StyledCardIconContainer = styled(Grid)`
  margin-bottom: ${props => props.theme.typography.pxToRem(30)};
  max-width: ${props => props.theme.typography.pxToRem(50)};
`

export const StyledOverviewTitle = styled.span`
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.theme.typography.pxToRem(26)};
  font-weight: 500;
  transition: color 0.3s ease;
  text-decoration: underline;
`

export const StyledOverviewChevron = styled.div`
  width: ${props => rem(35, props.theme)};
  height: ${props => rem(35, props.theme)};
  margin-left: ${props => rem(25, props.theme)};

  svg {
    fill: currentColor;
  }
`

export const StyledOverviewContainer = styled.div`
  display: inline-block;

  a {
    display: flex;
    align-items: center;
    color: ${props => props.theme.palette.text.primary};
    transition: color 0.2s ease;
  }

  &:hover {
    ${StyledOverviewTitle} {
      color: ${props => props.theme.palette.secondary.dark};
    }

    a {
      color: ${props => props.theme.palette.primary.main};
    }
  }
`

export const StyledHeaderContainer = styled(Container)`
  max-width: ${props => rem(1920, props.theme)};
  margin: auto;
  background-color: white;

  > header {
    max-width: ${props => rem(1920, props.theme)};
    right: unset;
  }
`


