import React from 'react'
import { IconComponent } from './iconComponentType'

export const FindUsIcon:IconComponent = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" { ...props } viewBox="0 0 30.671 38.048" >
      <g transform="translate(-252.656 -434.794)">
        <path fill="#f35a21" d="M280.575,441.386a15.305,15.305,0,0,0-25.166,0,15.305,15.305,0,0,0-1.784,14.1,11.983,11.983,0,0,0,2.211,3.651L267.211,472.5a1.058,1.058,0,0,0,1.564,0l11.367-13.354a12,12,0,0,0,2.213-3.644A15.316,15.316,0,0,0,280.575,441.386Zm-.144,13.4a9.97,9.97,0,0,1-1.844,3.022l-10.595,12.446L257.4,457.8a9.978,9.978,0,0,1-1.846-3.031,13.267,13.267,0,0,1,1.549-12.219,13.254,13.254,0,0,1,21.787,0A13.273,13.273,0,0,1,280.431,454.781Z" transform="translate(0)"/>
        <path fill="#f35a21" d="M266.092,440.937a7.255,7.255,0,1,0,7.253,7.255A7.262,7.262,0,0,0,266.092,440.937Zm0,12.454a5.2,5.2,0,1,1,5.2-5.2A5.206,5.206,0,0,1,266.092,453.391Z" transform="translate(1.901 1.89)"/>
      </g>
    </svg>
  )
}