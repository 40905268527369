import React from 'react'
import { IconComponent } from './iconComponentType'

export const ContactUsIcon:IconComponent = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 25.237 38.629">
      <defs>
      </defs>
      <g transform="translate(-200.8 -237.671)">
        <path fill="#f35a21" d="M222.44,237.671H204.4a3.6,3.6,0,0,0-3.6,3.6v31.439a3.6,3.6,0,0,0,3.6,3.6H222.44a3.6,3.6,0,0,0,3.6-3.6V241.266A3.6,3.6,0,0,0,222.44,237.671Zm1.759,30.235v4.8a1.76,1.76,0,0,1-1.759,1.757H204.4a1.759,1.759,0,0,1-1.758-1.757v-4.8Zm0-26.64v24.8H202.638v-24.8a1.759,1.759,0,0,1,1.758-1.757H222.44A1.76,1.76,0,0,1,224.2,241.266Z" transform="translate(0 0)" />
        <path fill="#f35a21" d="M213.418,274.007a2.823,2.823,0,1,0-2.823-2.823A2.826,2.826,0,0,0,213.418,274.007Zm0-4.033a1.21,1.21,0,1,1-1.21,1.21A1.212,1.212,0,0,1,213.418,269.974Z" transform="translate(0 0)" />
      </g>
    </svg>
  )
}