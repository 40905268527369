import BaseLink, { LinkProps as BaseLinkProps } from 'next/link'
import React from 'react'

export type LinkProps = Omit<BaseLinkProps, 'href'> & Pick<Partial<BaseLinkProps>, 'href'>

export const Link: React.FC<LinkProps> = ({ href, children, ...linkProps }) => {
  return href && href !== '#' ? (
    <BaseLink href={href} { ...linkProps }>
      {children}
    </BaseLink>
  ) : (
    <a href={href}>
      {children}
    </a>
  )
}