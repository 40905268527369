import React from 'react'
import { IconComponent } from './iconComponentType'

export const AnalyticsIcon: IconComponent = (props) => {
  return (
    <svg { ...props } viewBox="0 0 28.105 24.228">
      <defs>
        <linearGradient id="a" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox"><stop offset="0" stopColor="#ffb81c"/><stop offset="1" stopColor="#f35a21"/></linearGradient>
      </defs>
      <g transform="translate(28.105 24.228) rotate(180)">
        <path fill="none" d="M0,.614A1.447,1.447,0,0,0,.723,0,1.88,1.88,0,0,1,0,.614Z" transform="translate(16.514 14.792)"/>
        <path fill="none" d="M15.119,6.349a2.606,2.606,0,0,1,.388,1.357v0a2.423,2.423,0,1,1-4.845,0A2.03,2.03,0,0,1,11.1,6.4L8.625,3.925a2.6,2.6,0,0,1-1.357.388A2.026,2.026,0,0,1,5.96,3.877L1.55,8.286a2.605,2.605,0,0,1,.388,1.357A2.437,2.437,0,0,1,0,12.017v3.494a1.428,1.428,0,0,0,1.456,1.4l19.866-.1a1.521,1.521,0,0,0,1.018-1.357V.339A2.344,2.344,0,0,1,21.467,0Z" transform="translate(2.906 6.348)"/>
        <path fill="url(#a)" d="M1.454,0H1.4A1.429,1.429,0,0,0,0,1.455v.05a1.428,1.428,0,0,0,1.454,1.4c.04,0,.08,0,.12,0A1.454,1.454,0,0,0,1.454,0Z" transform="translate(0.97 14.535)"/>
        <path fill="url(#a)" d="M1.456,0a1.454,1.454,0,0,0,0,2.908h.054a1.428,1.428,0,0,0,1.4-1.454A1.454,1.454,0,0,0,1.456,0Z" transform="translate(24.23 2.907)"/>
        <path fill="url(#a)" d="M1.454,2.908A1.454,1.454,0,1,0,0,1.454v.053A1.428,1.428,0,0,0,1.454,2.908Z" transform="translate(8.723 6.783)"/>
        <path fill="url(#a)" d="M1.454,0H1.4A1.429,1.429,0,0,0,0,1.454,1.446,1.446,0,0,0,1.977,2.807,1.88,1.88,0,0,0,2.7,2.193a1.439,1.439,0,0,0,.208-.739A1.454,1.454,0,0,0,1.454,0Z" transform="translate(14.537 12.598)"/>
        <path fill="#1c1f2a" d="M28.057,3.89a2.418,2.418,0,0,0-1.939-1.9v0A2.441,2.441,0,0,0,23.743,0H18.962V.969h4.781a1.471,1.471,0,0,1,1.405,1.017,2.488,2.488,0,0,0-1.89,2.374,2.6,2.6,0,0,0,.388,1.357l-6.3,6.3a2.569,2.569,0,0,0-2.714,0L12.161,9.545A2.028,2.028,0,0,0,12.6,8.237a2.423,2.423,0,0,0-4.845,0,2.6,2.6,0,0,0,.388,1.357L3.732,14a2.352,2.352,0,0,0-.872-.342V2.423A1.5,1.5,0,0,1,4.362.969H9.84V0H4.362A2.4,2.4,0,0,0,1.94,2.376v11.24a2.423,2.423,0,0,0,0,4.749V21.8a2.4,2.4,0,0,0,2.376,2.423H23.743a2.4,2.4,0,0,0,2.423-2.375V6.738A2.417,2.417,0,0,0,28.057,3.89ZM15.691,11.834a1.881,1.881,0,0,1,1.545,2.957,1.447,1.447,0,0,1-.723.614,1.862,1.862,0,0,1-.823.2,1.884,1.884,0,0,1,0-3.768ZM10.308,6.451A1.884,1.884,0,1,1,8.424,8.336,1.884,1.884,0,0,1,10.308,6.451ZM2.5,17.757A2.032,2.032,0,0,1,.349,15.872,2.032,2.032,0,0,1,2.5,13.988a2.032,2.032,0,0,1,2.153,1.884A2.033,2.033,0,0,1,2.5,17.757ZM25.245,21.8a1.521,1.521,0,0,1-1.018,1.357l-19.866.1a1.428,1.428,0,0,1-1.456-1.4V18.365A2.437,2.437,0,0,0,4.844,15.99a2.606,2.606,0,0,0-.388-1.357l4.409-4.409a2.026,2.026,0,0,0,1.308.436,2.6,2.6,0,0,0,1.357-.388L14,12.745a2.03,2.03,0,0,0-.436,1.308,2.423,2.423,0,1,0,4.845,0v0a2.6,2.6,0,0,0-.388-1.357l6.348-6.349a2.344,2.344,0,0,0,.872.339Zm.4-15.354A2.153,2.153,0,1,1,27.8,4.3,2.153,2.153,0,0,1,25.65,6.451ZM8.308.971h3.447a.486.486,0,0,0,0-.971H8.3M20.044,0H16.126a.486.486,0,0,0,0,.971h3.918m-6.1,0a.485.485,0,0,0,.485-.485A.486.486,0,1,0,13.6.829.49.49,0,0,0,13.941.971Z" transform="translate(0 0)"/>
        <path fill="#1c1f2a" d="M4.306,1.884A2.032,2.032,0,0,0,2.153,0,2.032,2.032,0,0,0,0,1.884,2.032,2.032,0,0,0,2.153,3.768,2.032,2.032,0,0,0,4.306,1.884ZM2.2,3.454c-.04,0-.08,0-.12,0a1.428,1.428,0,0,1-1.454-1.4V2A1.429,1.429,0,0,1,2.023.548h.053A1.454,1.454,0,0,1,2.2,3.454Z" transform="translate(0.349 13.988)"/>
        <path fill="#1c1f2a" d="M2.153,0A2.153,2.153,0,1,0,4.306,2.153,2.153,2.153,0,0,0,2.153,0Zm.086,3.671H2.185A1.454,1.454,0,1,1,3.641,2.217,1.428,1.428,0,0,1,2.239,3.671Z" transform="translate(23.497 2.145)"/>
        <path fill="#1c1f2a" d="M1.884,3.768A1.884,1.884,0,1,0,0,1.884,1.884,1.884,0,0,0,1.884,3.768ZM.3,1.786A1.454,1.454,0,1,1,1.753,3.24,1.428,1.428,0,0,1,.3,1.838Z" transform="translate(8.424 6.451)"/>
        <path fill="#1c1f2a" d="M1.884,3.768a1.862,1.862,0,0,0,.823-.2A1.446,1.446,0,0,1,.73,2.218,1.429,1.429,0,0,1,2.131.764h.053A1.454,1.454,0,0,1,3.638,2.218a1.439,1.439,0,0,1-.208.739,1.882,1.882,0,1,0-1.545.811Z" transform="translate(13.807 11.834)"/>
      </g>
    </svg>
  )
}
