import React from 'react'
import { IconComponent } from './iconComponentType'

export const MoneyIcon:IconComponent = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props} viewBox="0 0 30.202 28.375">
      <defs>
        <linearGradient id="a" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stopColor="#ffb81c" /><stop offset="1" stopColor="#f35a21" /></linearGradient>
      </defs>
      <path fill="url(#a)" d="M1066.473-2357.34l2.854-1.967,1.22.761,4.817,8.118v1.935l-17.136,9.619,12.319-12.868v-1.312Z" transform="translate(-1046.051 2360.942)" />
      <g transform="translate(0 0)">
        <g transform="translate(0 0)">
          <path fill="#1c1f2a" d="M162.225,180.928a3.211,3.211,0,1,0-2.271-.941,3.19,3.19,0,0,0,2.271.941Zm-1.436-4.648a2.031,2.031,0,1,1,0,2.873,2.025,2.025,0,0,1,0-2.873Z" transform="translate(-149.634 -165.125)" />
          <path fill="#1c1f2a" d="M30.121,35.71l-1.787-6.668.423-.245a2.371,2.371,0,0,0,.868-3.239l-4.453-7.714a2.371,2.371,0,0,0-3.239-.868l-1.881,1.086-1.879-1.879a2.374,2.374,0,0,0-3.353,0L9.589,21.415a.59.59,0,1,0,.834.834l5.231-5.231a1.191,1.191,0,0,1,1.685,0l6.3,6.3a1.193,1.193,0,0,1,0,1.685L9.51,39.128a1.192,1.192,0,0,1-1.685,0l-6.3-6.3a1.193,1.193,0,0,1,0-1.685L6.669,26a.59.59,0,1,0-.834-.834L.693,30.311a2.374,2.374,0,0,0,0,3.353l4.49,4.49,1.059,3.954a2.37,2.37,0,0,0,2.9,1.677l19.3-5.171a2.371,2.371,0,0,0,1.677-2.9Zm-5.65-9.875a2.374,2.374,0,0,0,0-3.353l-3.555-3.555L22.523,18a1.193,1.193,0,0,1,1.627.436L28.6,26.149a1.191,1.191,0,0,1-.436,1.627l-13.338,7.7ZM28.862,36.92a1.183,1.183,0,0,1-.723.555l-19.3,5.171A1.193,1.193,0,0,1,7.383,41.8l-.531-1.98.139.139a2.374,2.374,0,0,0,3.353,0L11.607,38.7l15.67-9.047,1.7,6.362A1.183,1.183,0,0,1,28.862,36.92Z" transform="translate(0 -15.491)" />
          <path fill="#1c1f2a" d="M128.393,145.99a.59.59,0,1,0-.417-.173A.593.593,0,0,0,128.393,145.99Z" transform="translate(-120.264 -137.181)" />
        </g>
      </g>
    </svg>
  )
}
