import React from 'react'
import { IconComponent } from './iconComponentType'

export const MobileLendingIcon: IconComponent = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 37.549 37.549">
      <defs>
      </defs>
      <g xmlns="http://www.w3.org/2000/svg" transform="translate(-320.039 -173.4)">
        <path fill="#f35a21" d="M352.089,178.9a18.773,18.773,0,1,0,5.5,13.275A18.65,18.65,0,0,0,352.089,178.9Zm-29.024,7.631a16.731,16.731,0,0,1,31.488-.03l.18.5-11.211,2.865-.183-.316a5.228,5.228,0,0,0-9.051,0l-.184.315-11.219-2.836Zm14.727,22.351-.527-.048a16.734,16.734,0,0,1-14.972-19.3l.083-.521,11.2,2.831,0,.372a5.252,5.252,0,0,0,3.856,5.009l.355.1Zm-2.168-16.707a3.191,3.191,0,1,1,3.19,3.191A3.194,3.194,0,0,1,335.623,192.174Zm4.739,16.659-.526.048V197.32l.355-.1a5.252,5.252,0,0,0,3.856-5.012l0-.372,11.194-2.861.084.522a16.723,16.723,0,0,1-14.966,19.333Z"/>
      </g>
    </svg>
  )
}