import React from 'react'
import { Grid } from '@material-ui/core'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { StyledCardList, StyledTitleChevron, StyledTitleText } from './styledNavDropdownLinkCard'
import { Link, LinkProps } from '../link/link'

export type NavDropdownLinkCardProps =  React.ComponentProps<typeof Grid> & Pick<LinkProps, 'href'> & {
  title: string
}

export const NavDropdownLinkCard: React.FC<NavDropdownLinkCardProps> = ({ title, href, children, item=true, ...props }) => {
  return (
    <Grid sm={6} md={3} item={item} {...props}>
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
        <Grid item>
          <Link href={href} passHref>
            <StyledTitleText>
              { title }
              <StyledTitleChevron icon={faChevronRight}/>
            </StyledTitleText>
          </Link>
        </Grid>
      </Grid>
      <Grid container>
        <StyledCardList>
          { children }
        </StyledCardList>
      </Grid>
    </Grid>
  )
}
