import React from 'react'
import { IconComponent } from './iconComponentType'

export const UmbrellaIcon:IconComponent = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props} viewBox="0 0 29.337 29.337">
      <defs>
        <linearGradient id="a" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stopColor="#ffb81c" /><stop offset="1" stopColor="#f35a21" />
        </linearGradient>
      </defs>
      <path fill="url(#a)" d="M1533.572-2321.105h2.409v3.585l-2.129,2.491-3.293.338-2.213-1.463-.859-2.334h3.072l.638.968,1.959-.334Z" transform="translate(-1519.821 2343.42)" />
      <g transform="translate(0 0)">
        <path fill="#1c1f2a" d="M29.324,11.808A15,15,0,0,0,14.668,0,14.836,14.836,0,0,0,7.884,1.62.573.573,0,0,0,8.4,2.642a13.7,13.7,0,0,1,6.265-1.5,13.939,13.939,0,0,1,12.977,9.006,3.88,3.88,0,0,0-4.52.829,3.868,3.868,0,0,0-5.638,0,3.868,3.868,0,0,0-5.638,0,3.868,3.868,0,0,0-5.638,0,3.88,3.88,0,0,0-4.521-.829,13.867,13.867,0,0,1,2.783-4.53.573.573,0,1,0-.843-.776A14.981,14.981,0,0,0,.013,11.808a.573.573,0,0,0,1.051.417,2.717,2.717,0,0,1,4.657,0,.573.573,0,0,0,.981,0,2.717,2.717,0,0,1,4.657,0,.573.573,0,0,0,.981,0A2.7,2.7,0,0,1,14.1,10.969V21.689H13.3a.573.573,0,0,0-.573.573V24.64a.8.8,0,1,1-1.6,0,.573.573,0,0,0-.573-.573H7.8a.573.573,0,0,0-.573.573,4.7,4.7,0,1,0,9.4,0V22.262a.573.573,0,0,0-.573-.573h-.809V10.969A2.7,2.7,0,0,1,17,12.225a.573.573,0,0,0,.981,0,2.717,2.717,0,0,1,4.657,0,.573.573,0,0,0,.981,0,2.717,2.717,0,0,1,4.657,0,.573.573,0,0,0,1.051-.417ZM15.478,24.64a3.553,3.553,0,0,1-7.059.573h1.644a1.948,1.948,0,0,0,3.81-.573v-1.8h1.6v1.8Z" transform="translate(0 -0.001)" />
        <path fill="#1c1f2a" d="M94.574,52.227a.573.573,0,1,0-.405-.168A.577.577,0,0,0,94.574,52.227Z" transform="translate(-88.615 -48.154)" />
      </g>
    </svg>
  )
}
