import React from 'react'
import { IconComponent } from './iconComponentType'

export const TimesIcon: IconComponent = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.92" height="21.921" {...props} viewBox="0 0 21.92 21.921">
      <g transform="translate(-2674.074 -750)">
        <rect className="a" width="29" fill="#4d4d4d" height="2" transform="translate(2674.074 770.506) rotate(-45)" />
        <rect className="a" width="29" fill="#4d4d4d" height="2" transform="translate(2675.488 750) rotate(45)" />
      </g>
    </svg>
  )
}
